<template>
  <MainLayout>
    <v-row justify="center">
      <v-col lg="10" cols="12">
        <v-card>
          <v-card-title class="d-block">
            {{ $t('commons.stationTitle') }}
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col lg="9" cols="12">
                <div class="text-h6">
                  {{ $t('commons.prodTitle') }}
                </div>
                <v-divider class="mb-4"/>
                <v-row>
                  <v-col sm="3" md="2" lg="2" cols="6">
                    <LiveBlock
                      :loading="liveReload"
                      :param-details="getSpecificFromAll('voltageAC')"/>
                  </v-col>
                  <v-col sm="3" md="2" lg="2" cols="6">
                    <LiveBlock
                      :loading="liveReload"
                      :param-details="getSpecificFromAll('voltageDC')"/>
                  </v-col>
                  <v-col sm="3" md="2" lg="2" cols="6">
                    <LiveBlock
                      :loading="liveReload"
                      :param-details="getSpecificFromAll('sumPower')"/>
                  </v-col>
                  <v-col sm="3" md="2" lg="2" cols="6">
                    <LiveBlock
                      :loading="liveReload"
                      :param-details="getSpecificFromAll('sumEnergy')"/>
                  </v-col>
                  <v-col sm="3" md="2" lg="2" cols="6">
                    <LiveBlock
                      :loading="liveReload"
                      :param-details="getSpecificFromAll('sumDailyEnergy')"/>
                  </v-col>
                </v-row>
                <div class="hidden-lg-and-up mt-6 mb-6">
                  <div class="text-h6">
                    {{ $t('commons.paramTitle') }}
                  </div>
                  <v-divider class="mb-4"/>
                  <v-row>
                    <v-col sm="3" md="2" lg="2" cols="6" offset-lg="2">
                      <LiveBlock
                        :loading="liveReload"
                        :param-details="getSpecificFromAll('wind')"/>
                    </v-col>
                    <v-col sm="3" md="2" lg="2" cols="6" offset-lg="2">
                      <LiveBlock
                        :loading="liveReload"
                        :param-details="getSpecificFromAll('rpm')"/>
                    </v-col>
                    <v-col sm="3" md="2" lg="2" cols="6" offset-lg="2">
                      <LiveBlock
                        :loading="liveReload"
                        :param-details="getSpecificFromAll('modemRssi')"/>
                    </v-col>
                  </v-row>
                </div>
                <v-row>
                  <v-col sm="3" md="3" lg="2" cols="6">
                    <v-menu
                      v-model="pickers.fromDate"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="range.fromDate"
                          :label="$t('commons.dateFrom')"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="range.fromDate"
                        @input="pickers.fromDate = false"
                        no-title
                        scrollable
                        :locale="$i18n.locale"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col sm="3" md="3" lg="2" cols="6">
                    <v-menu
                      ref="fromTime"
                      v-model="pickers.fromTime"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="range.fromTime"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="range.fromTime"
                          :label="$t('commons.timeFrom')"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="pickers.fromTime"
                        v-model="range.fromTime"
                        full-width
                        scrollable
                        format="24h"
                        :max="range.toTime"
                        @click:minute="$refs.fromTime.save(range.fromTime)"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                  <v-col sm="3" md="3" lg="2" cols="6">
                    <v-menu
                      v-model="pickers.toDate"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="range.toDate"
                          :label="$t('commons.dateTo')"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="range.toDate"
                        @input="pickers.toDate = false"
                        no-title
                        scrollable
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col sm="3" md="3" lg="2" cols="6">
                    <v-menu
                      ref="toTime"
                      v-model="pickers.toTime"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="range.toTime"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="range.toTime"
                          :label="$t('commons.timeTo')"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="pickers.toTime"
                        v-model="range.toTime"
                        full-width
                        scrollable
                        format="24h"
                        :min="range.fromTime"
                        @click:minute="$refs.toTime.save(range.toTime)"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                  <v-col sm="3" md="3" lg="2" cols="6">
                    <v-row align="baseline">
                      <v-checkbox
                        @change="getRangeSumEnergy"
                        hide-details
                        v-model="rangeSumEnergyTrigger"
                        value="true"/>
                      <v-text-field
                        :disabled="rangeSumEnergyTrigger !== 'true'
                        || (rangeSumEnergyTrigger === 'true' && rangeLoading === true)"
                        class="top-big"
                        readonly
                        :value="rangeSumEnergy"
                        :loading="rangeLoading"
                        :label="'Suma wypr energii'" />
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-badge color="#2e93fa" inline>
                      <v-checkbox
                        :disabled="enableEnergy()"
                        @change="getAllWind"
                        v-model="dataType"
                        :label="$t('liveParams.labels.wind') + $t('liveParams.chartUnits.wind')"
                        value="wind"/>
                    </v-badge>
                    <v-badge color="#e91e63" inline>
                      <v-checkbox
                        :disabled="enableEnergy()"
                        class="ml-2"
                        v-model="dataType"
                        @change="getAllRpm"
                        :label="$t('liveParams.labels.rpm') + $t('liveParams.chartUnits.rpm')"
                        value="rpm"/>
                    </v-badge>
                    <v-badge color="#66DA26" inline>
                      <v-checkbox
                        :disabled="enableEnergy()"
                        class="ml-2"
                        @change="getAllVoltageAC"
                        v-model="dataType"
                        :label="
                        $t('liveParams.labels.voltageAC') + $t('liveParams.chartUnits.voltageAC')"
                        value="voltageAC"/>
                    </v-badge>
                    <v-badge color="#546E7A" inline>
                      <v-checkbox
                        :disabled="enableEnergy()"
                        class="ml-2"
                        @change="getAllVoltageDC"
                        v-model="dataType"
                        :label="
                        $t('liveParams.labels.voltageDC') + $t('liveParams.chartUnits.voltageDC')"
                        value="voltageDC"/>
                    </v-badge>
                    <v-badge color="#FF9800" inline>
                      <v-checkbox
                        :disabled="enableEnergy()"
                        class="ml-2"
                        @change="getAllSumPower"
                        v-model="dataType"
                        :label="
                        $t('liveParams.labels.sumPower') + $t('liveParams.chartUnits.sumPower')"
                        value="sumPower"/>
                    </v-badge>
                    <v-badge color="#0AC986" inline>
                      <v-checkbox
                        :disabled="disableEnergy()"
                        class="ml-2"
                        @change="getAllSumEnergy"
                        v-model="dataType"
                        :label="
                        $t('liveParams.labels.sumEnergy') + $t('liveParams.chartUnits.sumEnergy')"
                        value="sumEnergy"/>
                    </v-badge>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-overlay
                      absolute
                      :value="chartOverlay">
                      <v-progress-circular
                        indeterminate
                        size="64"/>
                    </v-overlay>
                    <ApexChart ref="customChart"
                               height="400px"
                               :options="options"
                               :series="series"/>
                  </v-col>
                  <v-col cols="2">
                  </v-col>
                </v-row>
              </v-col>
              <v-col lg="3" cols="12" class="hidden-md-and-down">
                <div class="text-h6">
                  {{ $t('commons.paramTitle') }}
                </div>
                <v-divider class="mb-4"/>
                <v-row>
                  <v-col md="2" lg="8" cols="6" offset-lg="2">
                    <LiveBlock
                      :loading="liveReload"
                      :param-details="getSpecificFromAll('wind')"/>
                  </v-col>
                  <v-col md="2" lg="8" cols="6" offset-lg="2">
                    <LiveBlock
                      :loading="liveReload"
                      :param-details="getSpecificFromAll('rpm')"/>
                  </v-col>
                  <v-col md="2" lg="8" cols="6" offset-lg="2">
                    <LiveBlock
                      :loading="liveReload"
                      :param-details="getSpecificFromAll('modemRssi')"/>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </MainLayout>
</template>

<script>
import moment from 'moment';
import debounce from 'debounce';
import axiosApi from '@/helpers/axios';
import MainLayout from '../components/Layouts/MainLayout.vue';
import LiveBlock from '../components/LiveBlock.vue';

export default {
  name: 'DashboardPage',
  components: { LiveBlock, MainLayout },
  data: () => ({
    headers: [
      { text: 'ID', value: 'id' },
      { text: 'Date', value: 'timestamp' },
      { text: 'Voltage', value: 'voltage' },
    ],
    chartOverlay: false,
    liveReload: false,
    ps: [],
    liveData: [],
    range: {
      fromDate: moment().format('YYYY-MM-DD'),
      fromTime: '00:00:00',
      toDate: moment().format('YYYY-MM-DD'),
      toTime: moment().format('HH:mm:ss'),
    },
    pickers: {
      fromDate: false,
      fromTime: false,
      toDate: false,
      toTime: false,
    },
    timerInterval: {},
    dataCollection: {},
    options: {
      chart: {
        id: 'range-for-chart',
      },
      xaxis: {
        type: 'datetime',
        labels: {
          datetimeUTC: false,
        },
      },
      colors: ['#a6d0fa', '#66DA26', '#546E7A', '#0ac986', '#FF9800', '#0AC986'],
      stroke: {
        width: 2,
      },
      legend: {
        show: false,
      },
      noData: {
        text: 'Brak danych',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
      },
    },
    series: [
      {
        name: 'wind',
        type: 'line',
        color: '#2e93fa',
        data: [],
      },
      {
        name: 'voltageDC',
        type: 'line',
        color: '#546E7A',
        data: [],
      },
      {
        name: 'voltageAC',
        type: 'line',
        color: '#66DA26',
        data: [],
      },
      {
        name: 'rpm',
        type: 'line',
        color: '#e91e63',
        data: [],
      },
      {
        name: 'sumPower',
        type: 'line',
        color: '#FF9800',
        data: [],
      },
      {
        name: 'sumEnergy',
        type: 'column',
        color: '#0AC986',
        data: [],
      },
    ],
    dataType: [],
    rangeSumEnergyTrigger: false,
    rangeSumEnergy: '0 kWh',
    rangeLoading: false,
  }),

  created() {
    this.getLive().then(() => this.callInterval());
  },

  beforeDestroy() {
    clearInterval(this.timerInterval);
  },

  watch: {
    range: {
      handler() {
        Promise.all(this.dataType.map(async (item) => {
          await this.handleFunctionCall(`getAll${this.capitalize(item)}`);
        }));

        if (this.rangeSumEnergyTrigger === 'true') {
          this.getRangeSumEnergy();
        }
      },
      deep: true,
    },
  },

  methods: {
    capitalize: (s) => (
      s && s[0].toUpperCase() + s.slice(1)
    ),
    handleFunctionCall(functionName) {
      this[functionName]();
    },
    createHumanDate(timestamp) {
      return moment.unix(timestamp / 1000).format('DD.MM.YYYY');
    },
    convertToDays() {
      const diff = moment(this.range.toDate).diff(moment(this.range.fromDate), 'days');
      return {
        from: moment(`${this.range.fromDate} ${this.range.fromTime}`)
          .format('X'),
        to: moment(`${this.range.toDate} ${this.range.toTime}`)
          .format('X'),
        days: diff,
      };
    },
    convertRangeToBe() {
      return {
        from: moment(`${this.range.fromDate} ${this.range.fromTime}`)
          .format('x'),
        to: moment(`${this.range.toDate} ${this.range.toTime}`)
          .format('x'),
      };
    },
    getSpecificFromAll(name) {
      const rtn = this.liveData.find((item) => item.name === name) || { name, value: 0 };
      return { ...rtn, value: rtn.value.toFixed(2) };
    },
    getLive() {
      this.liveReload = true;
      return axiosApi.get(`${process.env.VUE_APP_BASE_URL}`, { params: { action: 'livePrev' } })
        .then((resp) => {
          this.liveData = resp.data.results.live;
        }).finally(() => { this.liveReload = false; });
    },
    reloadCharts() {
      this.$refs.customChart.updateSeries(this.series);
      this.chartOverlay = false;
    },
    makeCallForModems: debounce(function (val) {
      if (val.length > 0) {
        axiosApi.get(`${process.env.VUE_APP_BASE_URL}`, {
          params: {
            action: 'getModemDetails',
            ...this.convertRangeToBe(),
          },
        })
          .then((resp) => {
            this.overlay = false;
            this.series = [];
            this.series = [{
              color: '#546E7A',
              name: 'rssi',
              data: resp.data.results.modem.map((item) => ({
                x: item.datetime,
                y: item.rssi,
              })),
            }];
          });
      } else {
        this.series = [];
        this.overlay = false;
      }
    }, 2000),
    getAllWind() {
      this.chartOverlay = true;
      if (this.dataType.find((item) => item === 'wind')) {
        axiosApi.get(`${process.env.VUE_APP_BASE_URL}`, {
          params: {
            action: 'getWind',
            ...this.convertRangeToBe(),
          },
        })
          .then((resp) => {
            this.series.find((item) => item.name === 'wind')
              .data = resp.data.results?.wind?.map((item) => ({
                x: item.datetime,
                y: item.wind,
              }));
            this.reloadCharts();
          });
      } else {
        this.series.find((item) => item.name === 'wind').data = [];
        this.reloadCharts();
      }
    },
    getAllVoltageAC() {
      this.chartOverlay = true;
      if (this.dataType.find((item) => item === 'voltageAC')) {
        axiosApi.get(`${process.env.VUE_APP_BASE_URL}`, {
          params: {
            action: 'getWindInverter',
            ...this.convertRangeToBe(),
          },
        })
          .then((resp) => {
            this.series.find((item) => item.name === 'voltageAC')
              .data = resp.data.results?.windInv?.map((item) => ({
                x: item.datetime,
                y: item.voltage,
              }));
          }).finally(() => {
            this.reloadCharts();
          });
      } else {
        this.series.find((item) => item.name === 'voltageAC').data = [];
        this.reloadCharts();
      }
    },
    getAllVoltageDC() {
      this.chartOverlay = true;
      if (this.dataType.find((item) => item === 'voltageDC')) {
        axiosApi.get(`${process.env.VUE_APP_BASE_URL}`, {
          params: {
            action: 'getWindInverterLan',
            ...this.convertRangeToBe(),
          },
        })
          .then((resp) => {
            this.series.find((item) => item.name === 'voltageDC')
              .data = resp.data.results?.windInvLan?.map((item) => ({
                x: item.datetime,
                y: item.voltageIn,
              }));
          }).finally(() => {
            this.reloadCharts();
          });
      } else {
        this.series.find((item) => item.name === 'voltageDC').data = [];
        this.reloadCharts();
      }
    },
    getAllRpm() {
      this.chartOverlay = true;
      if (this.dataType.find((item) => item === 'rpm')) {
        axiosApi.get(`${process.env.VUE_APP_BASE_URL}`, {
          params: {
            action: 'getRpm',
            ...this.convertRangeToBe(),
          },
        })
          .then((resp) => {
            this.series.find((item) => item.name === 'rpm')
              .data = resp.data.results?.sensors?.map((item) => ({
                x: item.datetime,
                y: item.turbineRpm,
              }));
          }).finally(() => {
            this.reloadCharts();
          });
      } else {
        this.series.find((item) => item.name === 'rpm').data = [];
        this.reloadCharts();
      }
    },
    getAllSumEnergy() {
      this.chartOverlay = true;
      if (this.dataType.find((item) => item === 'sumEnergy')) {
        axiosApi.get(`${process.env.VUE_APP_BASE_URL}`, {
          params: {
            action: 'getEnergy',
            ...this.convertToDays(),
          },
        })
          .then((resp) => {
            const converted = Object.values(resp.data.results?.energyDays).map((i, k) => ({
              data: Object.keys(resp.data.results?.energyDays)[k],
              val: (i?.min === null || !i?.min || !i?.max)
                ? 0
                : (parseFloat(i.max) - parseFloat(i.min)).toFixed(2),
            }));

            this.series.find((item) => item.name === 'sumEnergy')
              .data = converted?.map((i) => ({
                x: moment(i.data, 'YYYY-MM-DD'),
                y: i.val,
              }));
          }).finally(() => {
            this.reloadCharts();
          });
      } else {
        this.series.find((item) => item.name === 'sumEnergy').data = [];
        this.reloadCharts();
      }
    },
    getAllDailyEnergy() {
      this.chartOverlay = true;
      if (this.dataType.find((item) => item === 'sumEnergy')) {
        axiosApi.get(`${process.env.VUE_APP_BASE_URL}`, {
          params: {
            action: 'getDailyEnergy',
            ...this.convertRangeToBe(),
          },
        })
          .then((resp) => {
            this.series.find((item) => item.name === 'sumEnergy')
              .data = resp.data.results?.sumEnergy?.map((item) => ({
                x: item.datetime,
                y: item.sumEnergy,
              }));
          }).finally(() => {
            this.reloadCharts();
          });
      } else {
        this.series.find((item) => item.name === 'sumEnergy').data = [];
        this.reloadCharts();
      }
    },
    getAllSumPower() {
      this.chartOverlay = true;
      if (this.dataType.find((item) => item === 'sumPower')) {
        axiosApi.get(`${process.env.VUE_APP_BASE_URL}`, {
          params: {
            action: 'getPower',
            ...this.convertRangeToBe(),
          },
        })
          .then((resp) => {
            this.series.find((item) => item.name === 'sumPower')
              .data = resp.data.results?.sumPower?.map((item) => ({
                x: item.datetime,
                y: item.sumPower,
              }));
          }).finally(() => {
            this.reloadCharts();
          });
      } else {
        this.series.find((item) => item.name === 'sumPower').data = [];
        this.reloadCharts();
      }
    },
    getRangeSumEnergy() {
      if (this.rangeSumEnergyTrigger === 'true') {
        this.rangeLoading = true;
        axiosApi.get(`${process.env.VUE_APP_BASE_URL}`, {
          params: {
            action: 'getSumEnergy',
            ...this.convertRangeToBe(),
          },
        })
          .then((resp) => {
            this.rangeLoading = false;
            this.rangeSumEnergy = `${resp?.data?.sumEnergy ?? 0} kWh`;
          });
      } else {
        this.rangeSumEnergy = '0 kWh';
      }
    },
    enableEnergy() {
      return this.dataType.includes('sumEnergy');
    },
    disableEnergy() {
      return this.dataType.length > 0 && !this.dataType.includes('sumEnergy');
    },
    callInterval() {
      this.timerInterval = setInterval(() => {
        this.getLive();
      }, 30000);
    },
  },
};
</script>

<style lang="scss" scoped>
.content-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.top-big {
  padding-top: 25px;
  width: 50px;
}
.v-card {
  min-height: 150px;

  &__title {
    text-align: center;
  }
}
.theme--light.v-label--is-disabled {
  color: rgba(0,0,0,0.2);
}
</style>
