<template>
  <v-card class="text-center" :loading="loading">
    <v-card-title class="text-md-subtitle-1 font-weight-bold">
      {{ $t(`liveParams.labels.${paramDetails.name}`) }}
    </v-card-title>
    <v-card-subtitle>
      {{ $t(`liveParams.labels.subName.${paramDetails.name}`) }}
      <template v-if="paramDetails.name === 'modemRssi'">
        <v-icon v-if="paramDetails.subValue && paramDetails.subValue.includes('OK')"
                color="success">mdi-check-circle
        </v-icon>
        <v-icon v-else
                color="red">mdi-cancel
        </v-icon>
      </template>
    </v-card-subtitle>
    <v-card-text>
      <span class="font-weight-bold live-results">
        {{ paramDetails.value }} {{ unitMap }}
      </span>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'LiveBlock',

  props: {
    paramDetails: {
      type: Object,
      required: true,
    },
    extraData: {
      type: Object,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    showIconInSub() {
      return this.paramDetails.subValue && this.paramDetails.subName.toLowerCase().includes('status');
    },

    unitMap() {
      switch (this.paramDetails.name) {
        case 'wind': {
          return 'm/s';
        }
        case 'modemRssi': {
          return 'RSSI';
        }
        case 'rpm': {
          return 'RPM';
        }
        case 'sumPower': {
          return 'kW';
        }
        case 'sumEnergy':
        case 'sumDailyEnergy':
        case 'total': {
          return 'kWh';
        }
        default: {
          return 'V';
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.live-results {
  font-size: 18px;
}

</style>
