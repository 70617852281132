<template>
  <div>
    <v-app-bar
      app
      color="#e8f3ff"
      class=""
    >
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-6"
          contain
          src="@/assets/img/logo-Infinitec-Color.png"
          transition="scale-transition"
          width="150"
        />
      </div>
      <v-toolbar-title class="hidden-sm-and-down">Infinitec Power App</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn depressed
             outlined
             class="pa-0 mr-2"
             @click="changeLanguage('en')">
        <country-flag country="gb"/>
      </v-btn>
      <v-btn depressed
             outlined
             class="pa-0 mr-2"
             @click="changeLanguage('pl')">
        <country-flag country="pl"/>
      </v-btn>
      <v-btn depressed
             outlined
             class="pa-0"
             @click="changeLanguage('cz')">
        <country-flag country="cz"/>
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <v-row class="fill-height">
          <v-col>
            <transition name="fade">
              <slot/>
            </transition>
            <v-overlay :value="showOverlay">
              <v-progress-circular
                indeterminate
                size="64"/>
            </v-overlay>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import CountryFlag from 'vue-country-flag';

export default {
  name: 'MainLayout',
  components: [CountryFlag],
  data: () => ({
    showOverlay: false,
  }),
  methods: {
    changeLanguage(country) {
      this.showOverlay = true;
      this.$root.$i18n.locale = country;
      setTimeout(() => {
        this.showOverlay = false;
      }, 2000);
    },
  },
};
</script>

<style lang="scss" scoped>
.flag {
  cursor: pointer;
}

</style>
